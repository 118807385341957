import Button from "@/components/Common/Buttons/Button/Button";
import AboutButton from "@/components/Common/Buttons/AboutButton/AboutButton";
import MarketplaceFilter from "@/views/MarketplaceCategoryPage/MarketplaceFilter/MarketplaceFilter";
import MarketplaceGrid from "@/views/MarketplaceCategoryPage/MarketplaceGrid/MarketplaceGrid";
import MarketplaceGridTitle from "@/views/MarketplaceCategoryPage/MarketplaceGridTitle/MarketplaceGridTitle";
import MarketplaceSortFilter from "@/views/MarketplaceCategoryPage/MarketplaceSortFilter/MarketplaceSortFilter";
import Pagination from "@/components/Components/Pagination/Pagination";

import PreLoader from "@/components/Common/PreLoader";
import InputField from '@/components/Common/Inputs/Input/Input';
import ModalCloseBtn from "@/components/Modal/ModalCloseBtn.vue";

export default {
    name: 'MarketpaceCategoryPage',
    components: {
        ModalCloseBtn,
        PreLoader,
        Button,
        MarketplaceFilter,
        MarketplaceGrid,
        MarketplaceSortFilter,
        MarketplaceGridTitle,
        AboutButton,
        InputField,
        Pagination
    },
    watch: {
        appliedFilters: {
            handler(newVal, oldVal) {
                this.updateFilters();
            },
            deep: true,
            immediate: false
        },
    },
    data() {
        return {
            limit: 10,
            offset: 0,
            total: null,
            pages: null,
            currentPage: 1,

            isPromoProductsPage: false,
            isNewProductsPage: false,

            filterContainerActive: false,
            products: null,
            category: null,
            filters: null,
            appliedFilters: {},
            priceFrom: null,
            priceTo: null,

            specifications: null,
            categoryId: null,
            orderBy: null,

            updateFiltersTimeout: null,
            productFiltersFetched: false,
        }
    },
    async mounted() {
        this.products = null;
        this.categegoryId = this.$route.params.id;

        if (this.categegoryId === 'promo') {
            this.isPromoProductsPage = true;
        }

        if (this.categegoryId === 'new') {
            this.isNewProductsPage = true;
        }

        if (!this.isNewProductsPage && !this.isPromoProductsPage) {
            const result = await this.$store.dispatch('product/getProductCategoryFilters', this.categegoryId);
            this.filters = result.filters;
            this.category = result.category;
            this.priceFrom = result.minPrice;
            this.priceTo = result.maxPrice;
        }

        this.updateSearchResult();
        this.productFiltersFetched = true;
    },
    methods: {
        setDelayedFilterUpdate() {
            let vm = this;
            if (this.updateFiltersTimeout !== null) {
                clearTimeout(this.updateFiltersTimeout);
                this.updateFiltersTimeout = null;
            }
            console.log('setDelayedFilterUpdate');
            this.updateFiltersTimeout = setTimeout(() => {
                vm.updateFilters();
            }, 1000);
        },
        toggleFilterContainer() {
            this.filterContainerActive = !this.filterContainerActive
        },
        async handlePageChange(page) {
            this.currentPage = page;
            this.offset = (page - 1)  * this.limit;
            await this.updateSearchResult();
        },
        async updateSearchResult() {
            if (this.isLoaded) {
                return;
            }

            const request = {};

            if (this.categegoryId && !this.isPromoProductsPage && !this.isNewProductsPage) {
                request.categoryId = this.categegoryId;
            }

            if (this.isPromoProductsPage) {
                request.promo = true;
            }

            if (this.isNewProductsPage) {
                request.new = true;
            }

            if (this.specifications) {
                request.specifications = this.specifications;
            }

            if (this.priceFrom) {
                request.priceFrom = this.priceFrom;
            }

            if (this.priceTo) {
                request.priceTo = this.priceTo;
            }

            if (this.isPromoProductsPage) {
                request.promo = true;
            }

            if (this.isNewProductsPage) {
                request.new = true;
            }

            this.products = null;
            const products = await this.$store.dispatch('product/searchProduct', {
                orderBy: this.orderBy,
                offset: this.offset,
                limit: this.limit,
                request: request
            });

            this.products = products.data;
            this.total = products.total;
            this.pages = Math.ceil(this.total / this.limit);
        },
        async clearFilters() {
            this.appliedFilters = {};
            await this.updateFilters();
        },
        async updateFilters() {
            let vm = this;
            const specifications = [];
            const appliedFilters = vm.appliedFilters;
            Object.keys(this.appliedFilters).forEach((key) => {
                appliedFilters[key].forEach((value) => {
                    if (typeof value === 'string') {
                        specifications.push({
                            title: key,
                            value: value
                        });
                    }
                });
            });
            vm.appliedFilters = appliedFilters

            console.log('updateFilters');
            this.specifications = specifications;
            await this.updateSearchResult();
        },
        async sortResults(option) {
            if (option === 'price_ascending') {
                this.orderBy = 'price.ASC';
            }
            if (option === 'price_descending') {
                this.orderBy = 'price.DESC';
            }
            if (option === 'create_date') {
                this.orderBy = 'createdAt.DESC';
            }

            await this.updateSearchResult();
        },
    },
}
